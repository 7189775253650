import React from "react";
import './Footer.css';
import Github from '../assets/github.png';
import Instagram from '../assets/instagram.png';
import LinkedIn from '../assets/linkedin.png';
import Logo from '../assets/logo.png';


const Footer = () => {
    return (
        <div className="footer-containter">
            <hr />
            <div className="footer">
                <div className="social-links">
                    <img src={Logo} style={{ width: '10rem'}}/>
                    <img src={Github} />
                    <img src={Instagram} />
                    <img src={LinkedIn} />
                </div> 
                <div className="developer">Developed by :- TP Sehgal</div>            
            </div>
            <div className="blur blur-f-1"></div>
            <div className="blur blur-f-2"></div>
        </div>
    )
}

export default Footer;